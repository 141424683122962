
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NForm, { FormValidatorNames, IFormContext, IFormLayout } from '@/uikit/forms/NForm.vue';
import NInput from '@/uikit/input/NInput.vue';
import SimpleText from '@/uikit/simple/SimpleText.vue';
import { NButton } from '@/uikit';
import NTextareaAutosize from '@/uikit/textarea/NTextareaAutosize.vue';
import UserRoles from '@/pages/users/form/UserRoles.vue';
import { User } from '@/api';
import { configModule } from '@/store/config';
import { actionHandler } from '@/store/data/ActionHandler';
import { getDefaultMetaFormFields } from '@/components/common/meta-form-fields';

function validatePasswordsEqual(context: IFormContext) {
  return context.model.password === context.model.password_confirm;
}

function validatePasswordLength(context: IFormContext) {
  return context.model.password?.length > 5;
}

function validatePrimaryGroup(context: IFormContext) {
  return !!context.model.primary_group;
}

@Options({
  name: 'UserForm',
  components: { NForm },
  emits: []
})
export default class UserForm extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: User;

  @Prop({ type: Boolean, default: false })
  readonly isNew!: boolean;

  public changePasswordEnabled = false;

  get isADUser() {
    return configModule.config.services.ffsecurity.active_directory && this.item?.ad_user;
  }

  get layout() {
    return [...this.names, ...this.passwords, ...this.commentAndRoles, ...this.meta];
  }

  get passwords() {
    const changePassword = [
      {
        classes: 'n-form-w-6 n-form-pad-10 control-l n-form-block-start',
        component: NButton,
        props: {
          i18n_label: 'common.password_change',
          type: 'link'
        },
        on: {
          click: () => {
            this.changePasswordEnabled = !this.changePasswordEnabled;
          }
        }
      }
    ];

    const passwords: IFormLayout = [
      [
        {
          path: 'password',
          classes: 'n-form-w-3 n-form-pad-10 label-m',
          i18n_label: 'common.password',
          component: NInput,
          props: { password: true },
          validators: [
            {
              handler: validatePasswordsEqual,
              i18n_message: 'errors.confirm.password'
            },
            {
              handler: validatePasswordLength,
              i18n_message: 'errors.minimum.password'
            },
            { name: 'required', i18n_message: 'errors.required.password' }
          ]
        },
        {
          path: 'password_confirm',
          classes: 'n-form-w-3 n-form-pad-10 label-m n-form-ml-1',
          i18n_label: 'common.password_repeat',
          component: NInput,
          props: { password: true }
        }
      ]
    ];

    if (this.isADUser) {
      return [];
    }

    return this.changePasswordEnabled || this.isNew ? passwords : changePassword;
  }

  get names() {
    const names: IFormLayout = [
      {
        path: 'real_name',
        classes: 'n-form-w-6 n-form-pad-10 label-m',
        i18n_label: 'common.name',
        component: NInput,
        validators: [{ name: FormValidatorNames.Required, i18n_message: 'errors.required.name' }]
      },
      {
        path: 'name',
        classes: 'n-form-w-6 n-form-pad-10 label-m',
        i18n_label: 'common.login',
        component: NInput,
        validators: [{ name: FormValidatorNames.Required, i18n_message: 'errors.required.login' }]
      }
    ];

    const adNames: IFormLayout = [
      {
        classes: 'n-form-w-6 n-form-color-400',
        component: SimpleText,
        props: { i18n_modelValue: 'common.user_ad_label', class: 'label-m' }
      },
      {
        path: 'real_name',
        classes: 'n-form-w-6 n-form-pad-10 n-form-block-start',
        i18n_label: 'common.name',
        component: SimpleText,
        props: { class: 'label-m' }
      },
      {
        path: 'name',
        classes: 'n-form-w-6 n-form-pad-10 label-m',
        i18n_label: 'common.login',
        component: SimpleText,
        props: { class: 'label-m' }
      }
    ];

    return this.isADUser ? adNames : names;
  }

  get commentAndRoles() {
    return [
      {
        path: 'comment',
        classes: 'n-form-w-6 n-form-pad-10 label-m n-form-block-start',
        i18n_label: 'common.comment',
        component: NTextareaAutosize
      },
      {
        classes: 'n-form-w-6 n-form-block-start',
        component: UserRoles,
        props: { item: this.item },
        validators: [{ handler: validatePrimaryGroup, i18n_message: 'errors.required.primary_group' }]
      }
    ];
  }

  get meta() {
    return this.isNew ? [] : getDefaultMetaFormFields();
  }

  validateForm(): boolean {
    const result = this.$refs.itemForm ? this.$refs.itemForm.validate() : true;
    !result && this.$refs.itemForm.displayErrors();
    return result;
  }

  async created() {
    actionHandler.getIsItemFormValid = this.validateForm.bind(this);
  }
}
